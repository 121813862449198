import { gsap } from "gsap";
const sections = document.querySelectorAll("[data-block-section]");
const tl = gsap.timeline({ duration: 0.7});
gsap.set([sections], {autoAlpha:0});

tl
    .to(sections, {
      autoAlpha: 1,
      stagger: 0.1
    }, "<+0.2")


