export default () => ({
    open: false,
    text: 'Let\'s Chat',
    disableLayer: document.querySelector('[data-screen]'),
    startColor: getComputedStyle(document.documentElement).getPropertyValue('--chameleon-color').trim(),
    handleState() {
        if (this.open) {
            document.documentElement.style.setProperty('--chameleon-color', this.startColor);
            this.disableLayer.classList.add('hidden');
        } else {
            document.documentElement.style.setProperty('--chameleon-color', 'hsl(204, 47%, 25%)');
            this.disableLayer.classList.remove('hidden');
        }
        this.open = !this.open;
        this.text = this.open ? 'Close' : 'Let\'s Chat';
    }
})