export default {
    darkCyan: {
        50: '#c5d6d6',
        100: '#acbdbd',
        200: '#94a5a5',
        300: '#7b8c8c',
        400: '#637474',
        500: '#4a5b5b',
        600: '#314343',
        700: '#182a2a',
        800: '#0b1a18',
        900: '#000000',
    },
    darkBlue: {
        500: "#030814"
    },
    lightBlue: {
        500: "#1aeefd"
    },

    'sideproject': {
        'teensy': 'hsl(194, 45%, 43%)',
    },
    radicalRed: {
        '50': 'hsl(353, 100%, 97%)',
        '100': 'hsl(352, 100%, 94%)',
        '200': 'hsl(349, 100%, 89%)',
        '300': 'hsl(349, 100%, 80%)',
        '400': 'hsl(347, 100%, 69%)',
        '500': 'hsl(345, 100%, 58%)',
        '600': 'hsl(343, 97%, 49%)',
        '700': 'hsl(342, 100%, 42%)',
        '800': 'hsl(340, 100%, 35%)',
        '900': 'hsl(338, 95%, 30%)',
        '950': 'hsl(342, 100%, 17%)',
    },
    bright: {
        '50': 'hsl(353, 100%, 97%)',
        '100': 'hsl(352, 100%, 94%)',
        '200': 'hsl(349, 100%, 89%)',
        '300': 'hsl(349, 100%, 80%)',
        '400': 'hsl(347, 100%, 69%)',
        '500': 'hsl(345, 100%, 58%)',
        '600': 'hsl(343, 97%, 49%)',
        '700': 'hsl(342, 100%, 42%)',
        '800': 'hsl(340, 100%, 35%)',
        '900': 'hsl(338, 95%, 30%)',
        '950': 'hsl(342, 100%, 17%)',
    },

    blizzardBlue: {
        '50': 'hsl(180, 100%, 96%)',
        '100': 'hsl(184, 100%, 90%)',
        '200': 'hsl(185, 100%, 83%)',
        '300': 'hsl(185, 99%, 69%)',
        '400': 'hsl(187, 92%, 53%)',
        '500': 'hsl(187, 100%, 43%)',
        '600': 'hsl(190, 98%, 36%)',
        '700': 'hsl(191, 87%, 31%)',
        '800': 'hsl(192, 74%, 27%)',
        '900': 'hsl(195, 69%, 24%)',
        '950': 'hsl(195, 84%, 15%)',
    },
    tangaroa: {
        '50': 'hsl(212, 100%, 96%)',
        '100': 'hsl(211, 100%, 93%)',
        '200': 'hsl(213, 100%, 87%)',
        '300': 'hsl(215, 100%, 80%)',
        '400': 'hsl(220, 100%, 72%)',
        '500': 'hsl(225, 100%, 65%)',
        '600': 'hsl(230, 96%, 59%)',
        '700': 'hsl(231, 74%, 51%)',
        '800': 'hsl(230, 70%, 41%)',
        '900': 'hsl(228, 60%, 34%)',
        '950': 'hsl(230, 60%, 16%)',
    },

    brightCyan: {
        50: '#80f5ff',
        100: '#66f3ff',
        200: '#4cf0ff',
        300: '#33eeff',
        400: '#19ebff',
        500: '#40fefd',
        600: '#00d7e6',
        700: '#00b4cc',
        800: '#0092b2',
        900: '#006f99',
    },
    richOrange: {
        50: '#ffa280',
        100: '#ff8f66',
        200: '#ff7c4c',
        300: '#ff6933',
        400: '#ff5719',
        500: '#ff5500',
        600: '#e64b00',
        700: '#cc4100',
        800: '#b23700',
        900: '#992d00',
    },
    deepSpace: {
        50: 'hsl(204, 47%, 75%)',
        100: 'hsl(204, 47%, 65%)',
        200: 'hsl(204, 47%, 55%)',
        300: 'hsl(204, 47%, 45%)',
        400: 'hsl(204, 47%, 35%)',
        500: 'hsl(204, 47%, 25%)',
        600: 'hsl(204, 47%, 15%)', // #011627 equivalent in HSL
        700: 'hsl(204, 47%, 10%)',
        800: 'hsl(204, 47%, 5%)',
        900: 'hsl(204, 47%, 0%)',
    },
    neonBlue: {
        50: 'hsl(197, 100%, 90%)',
        100: 'hsl(197, 100%, 80%)',
        200: 'hsl(197, 100%, 70%)',
        300: 'hsl(197, 100%, 60%)',
        400: 'hsl(197, 100%, 50%)',
        500: 'hsl(197, 100%, 40%)', // #00B5FF equivalent in HSL
        600: 'hsl(197, 100%, 30%)',
        700: 'hsl(197, 100%, 20%)',
        800: 'hsl(197, 100%, 10%)',
        900: 'hsl(197, 100%, 0%)',
    },
    radiantOrange: {
        50: 'hsl(15, 100%, 90%)',
        100: 'hsl(15, 100%, 80%)',
        200: 'hsl(15, 100%, 70%)',
        300: 'hsl(15, 100%, 60%)',
        400: 'hsl(15, 100%, 50%)',
        500: 'hsl(15, 100%, 40%)', // #FF5500 equivalent in HSL
        600: 'hsl(15, 100%, 30%)',
        700: 'hsl(15, 100%, 20%)',
        800: 'hsl(15, 100%, 10%)',
        900: 'hsl(15, 100%, 0%)',
    },
    cyberGrape: {
        50: 'hsl(270, 51%, 85%)',
        100: 'hsl(270, 51%, 75%)',
        200: 'hsl(270, 51%, 65%)',
        300: 'hsl(270, 51%, 55%)',
        400: 'hsl(270, 51%, 45%)',
        500: 'hsl(270, 51%, 35%)', // #663399 equivalent in HSL
        600: 'hsl(270, 51%, 25%)',
        700: 'hsl(270, 51%, 20%)',
        800: 'hsl(270, 51%, 15%)',
        900: 'hsl(270, 51%, 5%)',
    },
    darkSlate: {
        50: 'hsl(180, 14%, 90%)',
        100: 'hsl(180, 14%, 80%)',
        200: 'hsl(180, 14%, 70%)',
        300: 'hsl(180, 14%, 60%)',
        400: 'hsl(180, 14%, 50%)',
        500: 'hsl(180, 14%, 40%)', // #2F4F4F equivalent in HSL
        600: 'hsl(180, 14%, 30%)',
        700: 'hsl(180, 14%, 20%)',
        800: 'hsl(180, 14%, 10%)',
        900: 'hsl(180, 14%, 0%)',
    },
    electricCyan: {
        50: 'hsl(180, 100%, 90%)',
        100: 'hsl(180, 100%, 80%)',
        200: 'hsl(180, 100%, 70%)',
        300: 'hsl(180, 100%, 60%)',
        400: 'hsl(180, 100%, 50%)',
        500: 'hsl(180, 100%, 40%)', // #00FFFF equivalent in HSL
        600: 'hsl(180, 100%, 30%)',
        700: 'hsl(180, 100%, 20%)',
        800: 'hsl(180, 100%, 10%)',
        900: 'hsl(180, 100%, 0%)',
    },

}
