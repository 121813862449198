// Import all modules at once
import ScrollWatermark from './ScrollWatermark';
import Chameleon from './Chameleon';
import HighlightDetail from './HighlightDetail';

// Map the module names to the imported modules
const modulesMap = {
  'ScrollWatermark': ScrollWatermark,
  'Chameleon': Chameleon,
  'HighlightDetail': HighlightDetail,
};

const moduleElements = document.querySelectorAll('[data-module]');

for (let i = 0; i < moduleElements.length; i++) {
  const el = moduleElements[i];
  const name = el.getAttribute('data-module');

  // Use the name to get the correct module from the modulesMap
  const Module = modulesMap[name];

  // If the module exists, execute it
  if (Module) {
    new Module(el);
  } else {
    console.error(`Failed to load module: ${name}`);
  }
}
