import {gsap} from 'gsap';
import {ScrollTrigger} from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

export default class ScrollWatermark {
    constructor(el) {
        this.el = el;
        this.id = el.dataset.id;
        this.init();
    }

    init() {
        this.attachScrollTrigger();
    }

    attachScrollTrigger() {
        const watermark = document.querySelector(`[data-watermark][data-id="${this.id}"]`);

        const fadeIn = gsap.fromTo(watermark, {autoAlpha: 0}, {autoAlpha: 1, duration: 1, paused: true});
        const fadeOut = gsap.fromTo(watermark, {autoAlpha: 1}, {autoAlpha: 0, duration: 1, paused: true});
        const scrollContainer = document.getElementById('ScrollContainer');

        gsap.set(watermark, {autoAlpha: 0})
        // ScrollContainer
        ScrollTrigger.create({
            trigger: this.el,
            scroller: scrollContainer,
            start: "top center",  // Trigger when the top of the element hits the top of the viewport
            end: "bottom center",  // Trigger when the bottom of the element leaves the bottom of the viewport
            // markers: true,
            scrub: .3,
            onEnter: () => {
                fadeIn.play();
                fadeOut.pause(0)
            },  // Play fadeIn, reset fadeOut
            onLeave: () => {
                fadeOut.play();
                fadeIn.pause(0);
            }, // Play fadeOut, reset fadeIn
            onEnterBack: () => {
                fadeIn.play();
                fadeOut.pause(0);
            },  // Play fadeIn, reset fadeOut
            onLeaveBack: () => {
                fadeOut.play();
                fadeIn.pause(0);
            } // Play fadeOut, reset fadeIn
        });
    }


}
