import colors from '../../tailwind.dw.colors';

export const getHSL = (colorString) => {
  const [base, shade] = colorString.split("-");
  const color = colors[base];
  return color ? color[shade] : null;
};

export const future = () => {
  // Future code here
};
