import { gsap } from 'gsap';
import { Flip } from 'gsap/Flip';

gsap.registerPlugin(Flip);

export default class HighlightDetail {
  constructor(el) {
    this.el = el;
    this.wrapper = document.querySelector('[data-highlights-target-wrapper]');
    this.target = this.wrapper.querySelector('[data-highlights-target]');
    this.closeButtons = document.querySelectorAll('[data-highlights-close]');
    this.clone = null;
    this.init();
  }

  init() {
    if (!this.el || !this.target || !this.wrapper) {
      console.error("Element, target, or wrapper not found");
      return;
    }
    this.el.addEventListener('click', () => this.flipAnimation());
    this.closeButtons.forEach(button =>
        button.addEventListener('click', () => this.closeWindow()));
  }

  flipAnimation() {
    // Clone the clicked element and position it absolutely over the original
    this.clone = this.el.cloneNode(true);
    const rect = this.el.getBoundingClientRect();
    this.clone.style.position = 'absolute';
    this.clone.style.top = `${rect.top}px`;
    this.clone.style.left = `${rect.left}px`;
    this.clone.style.width = `${rect.width}px`;
    document.body.appendChild(this.clone);

    // Hide other wrappers and clear their content
    document.querySelectorAll('[data-highlights-target-wrapper]').forEach(wrapper => {
      wrapper.classList.add('hidden');
    });

    // Show the current wrapper
    this.wrapper.classList.remove('hidden');

    // Capture initial state
    const state = Flip.getState([this.el, this.target]);

    // Update content in the target
    this.target.innerHTML = this.el.innerHTML;

    // Apply Flip animation
    Flip.from(state, {
      duration: 1,
      ease: 'power1.inOut',
      absolute: true,
    });
  }

  closeWindow() {
    if (this.clone) {
      // Make the clone visible again for the reverse animation
      this.clone.style.visibility = 'visible';

      // Capture the current state
      const state = Flip.getState([this.clone, this.target]);

      // Clear target content and hide wrapper
      this.target.innerHTML = "";


      // Apply Flip animation to place it back in the DOM
      Flip.from(state, {
        duration: 0.1,
        ease: 'power1.inOut',
        absolute: true,
        onComplete: () => {
          // Remove the clone after animation
          this.clone.parentNode.removeChild(this.clone);
          this.clone = null;
          this.wrapper.classList.add('hidden');
        }
      });
    }
  }
}
