import {gsap} from "gsap";

const backdrops = document.querySelectorAll("[data-backdrop]");
const watermarks = document.querySelectorAll("[data-watermark][data-first]");

console.log(watermarks.length + " watermarks found.");

const tl = gsap.timeline({duration: 0.7});

if (backdrops.length > 0) {
    gsap.set(backdrops, {autoAlpha: 0});
}

if (watermarks.length > 0) {
    gsap.set(watermarks, {autoAlpha: 0});
}

if (watermarks.length > 0) {
    tl.to(watermarks, {
        autoAlpha: 1,
    }, "<+0.2");
}

if (backdrops.length > 0) {
    tl.to(backdrops, {
        autoAlpha: 1,
    });
}
