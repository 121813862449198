import {gsap} from 'gsap';
import {ScrollTrigger} from 'gsap/ScrollTrigger';
import {getHSL, future} from '../helpers';

// const getHSL = require('../helpers');

gsap.registerPlugin(ScrollTrigger);

export default class Chameleon {
    constructor(el) {
        this.el = el;
        this.vars();
        this.init();
    }

    vars() {
        this.root = document.documentElement;
        this.initialColor = getComputedStyle(this.root).getPropertyValue('--chameleon-color').trim();
        this.color = getHSL(this.el.dataset.color);
    }

    init() {
        this.attachScrollTrigger();
    }

    attachScrollTrigger() {
        const scrollContainer = document.getElementById('ScrollContainer');
        ScrollTrigger.create({
            trigger: this.el,
            scroller: scrollContainer,
            start: "top bottom",
            end: () => `bottom bottom-=15`, // ends when the bottom of the element is 15px past the bottom of the viewport
            onEnter: () => {
                this.changeColor(this.color)
            },
            onEnterBack: () => {
                this.changeColor(this.color)
            },
            onLeaveBack: () => {
                this.changeColor(this.initialColor)
            },
        });
    }


    changeColor(color) {
        this.root.style.setProperty('--chameleon-color', color);
    }
}
